import React from 'react';
import cover from './cover-crop.jpg';
import styled, { keyframes } from 'styled-components';
import gameplay from './steelpaw-gameplay-1.webm';
import gameplay2 from './steelpaw-gameplay-2.webm';
import gameplay3 from './steelpaw-gameplay-3.webm';
import gameplay4 from './CoarseWindyAmericanratsnake.webm';
import gameplay5 from './ReliableCrazyIrrawaddydolphin.webm';


const Body = styled.div`
  height: 100%;
  min-height: 100%;
  display: grid;
  background: #223;
`;

const Content = styled.div`
  background: #445;
  width: 768px;
  padding: 15px;
  border-radius: 7px;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    padding: 0;
  }
`;

const TextCell = styled.div`
  background: #334;
  color: #fff;

  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px;

  > :first-child {
    margin-top: 0;
  }
`;

const CoverImg = styled.img`
  width: 100%;
`;

const TheHeat = keyframes`
  0% {
    color: #ee0;
  }

  50% {
    color: #f00;
  }

  100% {
    color: #ee0;
  }
`;

const HotText = styled.span`
  font-weight: bold;
  animation: ${TheHeat} 0.5s linear infinite;
`;

const HotLink = styled.a`
  animation: ${TheHeat} 0.5s linear infinite;
`;

function App() {
  return (
    <Body className="App">
      <Content>
        <CoverImg src={cover} />
        <TextCell>
          <p><iframe src="https://store.steampowered.com/widget/1022820/" frameborder="0" width="100%" height="190"></iframe></p>
          <p>Welcome to the STEELPAW tournament, where the world's fiercest warriors gather to compete for glory, financial independence, and high fives all around.</p>
          <p>
            <video width="100%" controls autoPlay loop >
              <source src={gameplay} type="video/webm" />
            </video>
          </p>
          <p>Gather your friends, customize your character, and prepare to have a wild time with <HotText>SIZZLING HOT</HotText> online 4-player matches.</p>
          <p>
            <video width="100%" controls autoPlay loop >
              <source src={gameplay4} type="video/webm" />
            </video>
          </p>
          <p>Well? Can you feel the heat?</p>
          <p>
            <video width="100%" controls autoPlay loop >
              <source src={gameplay5} type="video/webm" />
            </video>
          </p>
          <p>Questions? Kind comments? Shoot me a line at <HotLink href="mailto:steelpawgame@gmail.com?Subject=About%20Steelpaw" target="_top">STEELPAWgame@gmail.com</HotLink>!</p>
          <p>Please, I implore you to join our newly created <HotLink href="https://discord.gg/fENXHeE" target="_top">Discord server</HotLink>! This will give you a direct feed into the development of STEELPAW. Tell us what you think and help shape the future of the game!</p>
          <p>Or maybe you want to reach out directly via more tweety means. <HotLink href="https://twitter.com/billymonks" target="_top">Tweet me on Twitter</HotLink>!</p>
          <p>
            <video width="100%" controls autoPlay loop >
              <source src={gameplay2} type="video/webm" />
            </video>
          </p>
          <p>
            <video width="100%" controls autoPlay loop >
              <source src={gameplay3} type="video/webm" />
            </video>
          </p>
        </TextCell>
      </Content>
    </Body>
  );
}

export default App;
